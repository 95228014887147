import React from 'react';

const Scroll = (props) => {
    function handleClick(e) {
        e.preventDefault();
        const target = document.querySelector(props.target);
        const elemPos = target.getBoundingClientRect().top + window.pageYOffset;
        document.querySelectorAll('.navbar__menu .navbar__item').forEach(element => {
            element.classList.remove('navbar__item--active');
        });
        e.target.parentElement.classList.add('navbar__item--active');
        window.scroll({ top: elemPos - 100, left: 0, behavior: 'smooth' });
    }

    return (<button onClick={handleClick} className={'navbar__item navbar__scroll-link ' + props.target.substring(1)}>
        <div className="navbar__icon">
            {props.text}
        </div>
    </button>);
};

export default Scroll;