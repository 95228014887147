import React from "react";
import './TeamLink.scss';

const TeamLink = ({children, link}) => {
    if (link.encoded) {
        return (
            <a href="/" className="team__card-icon" data-contact={link.url} onFocus={e => {
                e.target.href = atob(e.target.dataset.contact);
            }}>
                {children}
            </a>
        );
    } else {
        return (
            <a href={link.url} className="team__card-icon">
                {children}
            </a>
        );
    }
};

export default TeamLink;