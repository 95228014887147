import React from "react";
import './TeamCard.scss';
import {
    FaTwitter,
    FaGithub,
    FaGitlab,
    FaPhone,
    FaEnvelope
} from 'react-icons/fa';
import TeamLink from "../team-link/TeamLink";

const TeamCard = ({data, links, imageURL}) => {
    return (
        <div className="item item-4 team__item">
            <div className="team__card">
                <img className="team__card-image" src={imageURL} alt={data.name}/>
                <h1 className="title team__card-title">{data.name}</h1>
                <div className="team__card-content">
                    {data.links.map(link => {
                        if (links === undefined) {
                            switch (link.name) {
                                case 'GitHub':
                                    return (<TeamLink key={link.url} link={link}><FaGithub/></TeamLink>);
                                case 'GitLab':
                                    return (<TeamLink key={link.url} link={link}><FaGitlab/></TeamLink>);
                                case 'Twitter':
                                    return (<TeamLink key={link.url} link={link}><FaTwitter/></TeamLink>);
                                case 'Phone':
                                    return (<TeamLink key={link.url} link={link}><FaPhone/></TeamLink>);
                                case 'Mail':
                                    return (<TeamLink key={link.url} link={link}><FaEnvelope/></TeamLink>);
                                default:
                                    return (<TeamLink key={link.url} link={link}>{link.name}</TeamLink>);
                            }
                        } else {
                            let linkIcons = [];
                            links.filter(displayLink => displayLink === link.name).forEach(() => {
                                switch (link.name) {
                                    case 'GitHub':
                                        linkIcons.push(<TeamLink key={link.url} link={link}><FaGithub/></TeamLink>); break;
                                    case 'GitLab':
                                        linkIcons.push(<TeamLink key={link.url} link={link}><FaGitlab/></TeamLink>); break;
                                    case 'Twitter':
                                        linkIcons.push(<TeamLink key={link.url} link={link}><FaTwitter/></TeamLink>); break;
                                    case 'Phone':
                                        linkIcons.push(<TeamLink key={link.url} link={link}><FaPhone/></TeamLink>); break;
                                    case 'Mail':
                                        linkIcons.push(<TeamLink key={link.url} link={link}><FaEnvelope/></TeamLink>); break;
                                    default:
                                        linkIcons.push(<TeamLink key={link.url} link={link}>{link.name}</TeamLink>); break;
                                }
                            });
                            return linkIcons;
                        }
                    })}
                </div>
            </div>
        </div>);
};

export default TeamCard;